
import Footer from "@/components/Footer.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "About",
  components: {
    Footer,
  },
})
export default class About extends Vue {}
